import React, {useEffect, useRef, useState} from "react";
import "../../App.css";
import "./Header.css";
import Logo from "../../assets/logo.png";
import {AiFillSetting, AiOutlineUser,} from "react-icons/ai";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {Dropdown} from "antd";
import {LogOut} from "../../redux/slices/auth-slice";
import {useDispatch, useSelector} from "react-redux";


function Header({}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const {user} = useSelector((state) => state.auth);
    const [isSetting, setIsSetting] = useState(false);
    const {newCases = []} = useSelector((state) => state.adjustment);
    const [isLoading, setIsLoading] = useState(true);
    const [isFromHeader, setIsFromHeader] = useState(false);
    const [currentUser, setCurrentUser] = useState("");
    useEffect(() => {
        if (!isLoading && isFromHeader) {
            setIsFromHeader(false);
        }
    }, [newCases, isLoading, isFromHeader]);


    const getPathName = () => {
        return window?.location?.pathname || "";
    };

    const getQueryString = () => {
        return window?.location?.search || "";
    };

    const items = [
        {
            label: (
                <div
                    onClick={() => {
                        dispatch(LogOut());
                        navigate("/");
                    }}
                >
                    Logout
                </div>
            ),
            key: "0",
        },
    ];
    return (
        <div className="header_main shadow-sm">
            <div className="header_container flex_between">
                <div className="header_container header_flex">
                    <div className="logo">
                        <img src={Logo} alt="SOS FBA"/>
                    </div>
                    <>
                        {user?.role === "SuperAdmin" ? (
                            <div className="header_flex">
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/admin/dashboard" ? "active" : ""
                                    }`}
                                    to="/admin/dashboard"
                                >
                                    Dashboard
                                </Link>
                            </div>) : <></>}
                        {user?.role === "Admin" ? (
                            <div className="header_flex">
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/users" ? "active" : ""
                                    }`}
                                    to="/users"
                                >
                                    Users
                                </Link>
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/admin-all-cases" ? "active" : ""
                                    }`}
                                    to={"/admin-all-cases" + getQueryString()}
                                >
                                    All cases
                                </Link>
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/admin-new-cases" ? "active" : ""
                                    }`}
                                    to={"/admin-new-cases" + getQueryString()}
                                >
                                    New Cases
                                </Link>
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/manage-new-cases" ? "active" : ""
                                    }`}
                                    to={"/manage-new-cases" + getQueryString()}
                                >
                                    Manage New Cases
                                </Link>
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/lost-inbound" ? "active" : ""
                                    }`}
                                    to={"/lost-inbound" + getQueryString()}
                                >
                                        Lost Inbound
                                </Link>
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/invoices" ? "active" : ""
                                    }`}
                                    to={"/invoices" + getQueryString()}
                                >
                                    Invoices
                                </Link>
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/reimbursements" ? "active" : ""
                                    }`}
                                    to="/reimbursements"
                                >
                                    Reimbursements
                                </Link>
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/stores" ? "active" : ""
                                    }`}
                                    to="/stores"
                                >
                                    Stores
                                </Link>
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/leads" ? "active" : ""
                                    }`}
                                    to="/leads"
                                >
                                    Leads
                                </Link>
                                {currentUser}&nbsp;
                            </div>
                        ) : <></>}
                        {user?.role === "Operator" ? (
                            <div className="header_flex">
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/users" ? "active" : ""
                                    }`}
                                    to="/users"
                                >
                                    Users
                                </Link>
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/admin-all-cases" ? "active" : ""
                                    }`}
                                    to={"/admin-all-cases" + getQueryString()}
                                >
                                    All cases
                                </Link>
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/manage-new-cases" ? "active" : ""
                                    }`}
                                    to={"/manage-new-cases" + getQueryString()}
                                >
                                    Manage New Cases
                                </Link>
                                {currentUser}&nbsp;
                            </div>
                        ) : <></>}
                        {user?.role === "Seller" ? (
                            <div className="header_flex">
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/seller/users" ? "active" : ""}`}
                                    to="/seller/users"
                                >
                                    Users
                                </Link>
                                {currentUser}&nbsp;
                            </div>
                        ) : <></>}
                        {user?.role === "Card" || user?.role === "Iban" || user?.role === "Cash" ? (
                            <div className="header_flex">
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/all-cases" ? "active" : ""
                                    }`}
                                    to="/all-cases"
                                >
                                    All cases
                                </Link>
                                {user?.newCasesVisible ?
                                    <div style={{position: 'relative'}}>
                                        <Link
                                            className={`ml-4 menus ${getPathName() === "/new-cases" ? "active" : ""}`}
                                            to="/new-cases"
                                        >
                                            New cases
                                        </Link>
                                    </div>
                                    : <></>}
                                <Link
                                    className={`ml-4 menus ${getPathName() === "/invoices" ? "active" : ""
                                    }`}
                                    to="/invoices"
                                >
                                    Invoices
                                </Link>
                            </div>
                        ) : <></>}
                    </>
                </div>
                <div className="menu_logo_container">
                    {user?.email}&nbsp;
                    {user.role === 'Seller' ? <></> : <>

                        {user?.role !== "Admin" ?
                            <AiFillSetting
                                className="menus menu_icon_margin"
                                onClick={() => setIsSetting(!isSetting)}
                            />
                            : null}

                    </>}
                    <Dropdown
                        menu={{
                            items,
                        }}
                        trigger={["click"]}
                    >
                        <AiOutlineUser className="menus"/>
                    </Dropdown>
                </div>
            </div>
            {user.role !== 'Seller' && isSetting && (
                <div
                    className="setting_flyout"
                    ref={wrapperRef}
                    onBlur={() => setIsSetting(false)}
                >
                    <div className="setting_item_wrapper">
                        <div
                            className="setting_flyout_item"
                            onClick={() => {
                                navigate("/payment-setting");
                                setIsSetting(false);
                            }}
                        >
                            Payment Setting
                        </div>
                    </div>
                    <div className="setting_item_wrapper">
                        <div
                            className="setting_flyout_item"
                            onClick={() => {
                                navigate("/stores");
                                setIsSetting(false);
                            }}
                        >Amazon Accounts
                        </div>
                    </div>
                </div>
            )}
            <div className="header_border"></div>
        </div>
    );
}

export default Header;

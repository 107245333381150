import React from "react";
import Table from "react-bootstrap/Table";
import {Pagination} from "antd";
import {useSelector} from "react-redux";
import TableTh from "./TableTh/TableTh";

import "./TableView.css";
import Spinner from "react-bootstrap/Spinner";

function TableView({
                       loading,
                       Rows,
                       data,
                       modal,
                       setIsModalOpen,
                       total,
                       setPageNo,
                       setSize,
                       setFnsku,
                       setTransactionItemId,
                       setType,
                       setDocumentId,
                       setCountry,
                       allColumns,
                       setActiveOptions,
                       activeOptions,
                       pageNo,
                       size,
                       setIsReload,
                       isReload,
                       setTransactionUpdated,
                   }) {
    const {user} = useSelector((state) => state.auth);
    const isAdmin = user?.role === "Admin";

    return (
        <>
            <div>
                <div
                    style={{
                        maxHeight: 'calc(100vh - 140px)',
                        overflowX: "scroll",
                        overflowY: "scroll",
                        whiteSpace: "nowrap",
                    }}
                >
                    <Table className="heading" locale={{emptyText: 'xxx'}} scroll={{y: `calc(100vh - 250px)`}}
                    >
                        <thead style={{
                            display: 'block',
                            background: 'white', zIndex: '10',
                        }}>
                        <tr style={{ position: 'sticky', top: '0',}}>
                            {allColumns?.filter(x => !x.hidden).map((col) => {
                                return col.isAdminOnly ? (
                                    isAdmin && (
                                        <TableTh
                                            col={col}
                                            setActiveOptions={setActiveOptions}
                                            activeOptions={activeOptions}
                                        />
                                    )
                                ) : col.isUserOnly ? (
                                    !isAdmin && (
                                        <TableTh
                                            col={col}
                                            setActiveOptions={setActiveOptions}
                                            activeOptions={activeOptions}
                                        />
                                    )
                                ) : (
                                    <TableTh
                                        col={col}
                                        setActiveOptions={setActiveOptions}
                                        activeOptions={activeOptions}
                                    />
                                );
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {!loading && data &&
                            data?.map((item, index) => (
                                <tr
                                    className="hv"
                                    key={index}
                                >
                                    <Rows
                                        index={index}
                                        item={item}
                                        setFnsku={setFnsku}
                                        setTransactionItemId={setTransactionItemId}
                                        setType={setType}
                                        setDocumentId={setDocumentId}
                                        setIsModalOpen={setIsModalOpen}
                                        setCountry={setCountry}
                                        setIsReload={setIsReload}
                                        isReload={isReload}
                                        setTransactionUpdated={setTransactionUpdated}
                                    />
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                {loading && (
                    <div className={'box content'}><Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner></div>
                )}
            </div>
            {/* {modal} */}
            {
                <div
                    style={{
                        position: "absolute",
                        background: "transparent",
                        bottom: 0,
                        right: 0,
                        alignContent: 'right',
                        padding: "0px 20px 20px 20px",
                        width: "100%",
                        zIndex: "500",
                    }}
                >
                    <Pagination
                        defaultCurrent={pageNo}
                        current={pageNo}
                        total={total ? total : 0}
                        showSizeChanger
                        showTitle
                        showTotal={(totalVal, range) => {
                            return `Displaying ${total <= (pageNo * data.length) ? total : (pageNo * data.length)} of ${total || 0} results`;
                        }}
                        className="mt-3 pagination_Container"
                        onChange={(no, size) => {
                            setPageNo(no);
                        }}
                        defaultPageSize={100}
                        pageSizeOptions={[20, 50, 100, 200, 500]}
                        onShowSizeChange={(current, size) => {
                            setSize(size);
                        }}
                    />
                </div>
            }
        </>
    );
}

export default TableView;

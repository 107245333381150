import React, {useEffect, useState} from "react";
import TableView from "../../Components/TableView";
import Rows from "./Rows";
import {NEW_CASE_COLUMNS_ADMIN} from "../../utils/constant";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import {Button, Card, Col, Modal, Row} from "antd";
import {useSelector} from "react-redux";
import {downloadXlsx, transformDataWithColumns} from "../../utils/utils";

function AdminNewCases() {
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [size, setSize] = useState(100);
    const [caseType, setCaseType] = useState("All");
    const [storeValue, setStoreValue] = useState("All");
    const [fnsku, setFnsku] = useState("");
    const [transactionItemId, setTransactionItemId] = useState("");
    const [type, setType] = useState("");
    const [documentId, setDocumentId] = useState("");
    const [country, setCountry] = useState("");
    const [row, setRow] = useState({});
    const [activeOptions, setActiveOptions] = useState({});
    const [isReload, setIsReload] = useState(false);
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [newCases, setNewCases] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalEstimate, setTotalEstimate] = useState(0);
    const [storeName, setStoreName] = useState("")
    const {user} = useSelector((state) => state.auth);
    const [downloadModalOpen, setDownloadModalOpen] = useState(false)
    const [downloadEmail, setDownlaodEmail] = useState("")
    const [wordElements, setWordElements] = useState([])
    const [modalWordOpen, setModalWordOpen] = useState(false)
    const [groupCaseId, setGroupCaseId] = useState("")
    const [userData, setUserData] = useState(null)
    const [blockedAccount, setBlockedAccount] = useState(false)
    const [group, setGroup] = useState(20)

    useEffect(() => {
        getNewCases({
            userId: searchParams.get('user'),
            storeId: storeValue,
            adjustmentType: caseType,
            skip: (pageNo - 1) * size,
            limit: size,
            filters: activeOptions
        })
    }, [pageNo, size, caseType, storeValue, activeOptions]);

    const getNewCases = async (params) => {
        setIsLoading(true)
        await axios.get('/cases/new-cases', {
            params
        }).then(res => {
            setIsLoading(false)
            if (res && res.status === 200 && res.data && res.data.success) {
                setNewCases(res.data.newCases);
                setTotal(res.data.total);
                setTotalQuantity(res.data.totalQuantity)
                setTotalEstimate(res.data.totalEstimate)
            } else {
                setNewCases([]);
                setTotal(0);
            }
        }).catch(err => {
            setIsLoading(false)
            toast.error(err.message);
            navigate("/users");
        });
    }
    const exportFilenameFirst = "NewCases_"
    const exportFilenameLast = ".xlsx"


    return (
        <>
            <Card>
                <div>
                    <Row>
                        <Col md={3}>
                            <h3><strong>Riepilogo casi</strong></h3>
                        </Col>
                        <Col md={3}>
                            <p>Totale prodotti: <strong>{totalQuantity}</strong></p>
                        </Col>
                        <Col md={6}>
                            <p>Stima importo prodotti persi: <strong>€{totalEstimate}</strong></p>
                        </Col>
                        {
                            searchParams.get('user') ?
                                <Col md={6}>
                                    <p>Store: <strong>{storeName}</strong></p>
                                </Col> : <></>
                        }
                    </Row>
                </div>
            </Card>
            {user.role === 'Admin' ? <Card>
                    <Button
                        type="primary"
                        style={{marginLeft: "15px",}}
                        onClick={(e) => {
                            setDownloadModalOpen(false)
                            setDownlaodEmail("")
                            downloadXlsx(e, transformDataWithColumns(NEW_CASE_COLUMNS_ADMIN, newCases), exportFilenameFirst + (searchParams.get('user') && searchParams.get('user') !== "" ? userData?.email + '_' + userData?.storeName : "") + exportFilenameLast)
                        }}>
                        Download Excel Current Data
                    </Button>
                </Card>
                : <></>}
            <TableView
                allColumns={NEW_CASE_COLUMNS_ADMIN}
                setActiveOptions={setActiveOptions}
                activeOptions={activeOptions}
                setRow={setRow}
                Rows={Rows}
                setFnsku={setFnsku}
                setTransactionItemId={setTransactionItemId}
                setType={setType}
                setDocumentId={setDocumentId}
                setCountry={setCountry}
                pageNo={pageNo}
                size={size}
                data={newCases}
                loading={isLoading}
                setIsModalOpen={setIsModalOpen}
                setPageNo={setPageNo}
                setSize={setSize}
                total={total}
                setIsReload={setIsReload}
                isReload={isReload}
            />
            <Modal
                title="Client's Account is Inactive"
                open={blockedAccount}
                onOk={() => navigate('/users')}
                okText="Ok"
                closable={false}
                cancelButtonProps={{style: {display: 'none'}}}
            >
                <p>
                    Client's account is blocked
                </p>
            </Modal>
        </>
    );
}

export default AdminNewCases;
